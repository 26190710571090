import cx from 'classnames';
import { meetsContrastGuidelines } from 'polished';
import { logWarning } from '@dx-ui/framework-logger';

import { availableThemes } from '../../config/themes/componentThemes';

import type { ThemeColors as GqlThemeColors, Maybe } from '@dx-ui/gql-types';
import type { LayoutData } from '../utils/server/getLayout.types';

export type ThemeColors = {
  primary: string;
  primaryContrast: string;
  secondary: string;
  secondaryContrast: string;
  tertiary: string;
  tertiaryContrast: string;
};

/** Denotes that this component supports custom theming. */
export type TWithCustomTheme<T> = T & {
  /**
   * Applies custom theming for components not rendered through the component mapper.
   * Styles are configured in the {@linkcode componentBrandClassMap}.
   */
  wrapperClass?: string;
};

type LayoutComponents = 'alertBanner' | 'globalNav' | 'navShopForm' | 'footer';

type CustomClassMap = { [K in LayoutComponents]?: string | string[] };

export const className = {
  colorPrimaryOverride: 'color-primary-override',
  navItemHoverBorder: 'nav-item-hover-border',
  navItemHoverPrimary: 'nav-item-hover-primary',
  navItemHoverSecondary: 'nav-item-hover-secondary',
  navItemHoverTertiary: 'nav-item-hover-tertiary',
  navLowerPrimary: 'nav-lower-primary',
  navLowerSecondary: 'nav-lower-secondary',
  navLowerTertiary: 'nav-lower-tertiary',
  primary: 'custom-primary',
  secondary: 'custom-secondary',
  tertiary: 'custom-tertiary',
} as const;

export const GU: CustomClassMap = {
  alertBanner: availableThemes.primary,
  globalNav: [
    availableThemes.primary,
    className.primary,
    className.navItemHoverBorder,
    className.navItemHoverTertiary,
    className.navLowerSecondary,
    className.colorPrimaryOverride,
  ],
  navShopForm: [availableThemes.tertiary, className.colorPrimaryOverride],
} as const;

const OU: CustomClassMap = {
  alertBanner: ['text-primary', 'bg-quarternary'],
  globalNav: availableThemes.primary,
  navShopForm: availableThemes.secondary,
} as const;

const LX: CustomClassMap = {
  alertBanner: availableThemes.tertiary,
  navShopForm: availableThemes.secondary,
} as const;

const componentBrandClassMap: { [brandCode: string]: CustomClassMap } = {
  GU,
  LX,
  OU,
} as const;

const hasProperlyDefinedTheme = (theme: Pick<GqlThemeColors, keyof ThemeColors>) =>
  Object.keys(theme).length === 6 &&
  Object.values(theme).every((value) => /^#[0-9A-Fa-f]{6}$/i.test(value ?? ''));

const themeMeetsContrast = (theme: Pick<GqlThemeColors, keyof ThemeColors>, ctyhocn: string) =>
  [
    ['primary', theme.primary, theme.primaryContrast],
    ['secondary', theme.secondary, theme.secondaryContrast],
    ['tertiary', theme.tertiary, theme.tertiaryContrast],
  ].every(([theme, color, contrastColor]) => {
    const { AA: meetsContrast } = meetsContrastGuidelines(color as string, contrastColor as string);

    if (!meetsContrast) {
      logWarning('PROPERTY_LEVEL_THEMING_CONTRAST_VIOLATION', { ctyhocn, theme });
    }

    return meetsContrast;
  });

export const isValidTheme = (
  theme: Maybe<Pick<GqlThemeColors, keyof ThemeColors>> | undefined,
  ctyhocn: string
) => {
  if (theme) {
    return hasProperlyDefinedTheme(theme) && themeMeetsContrast(theme, ctyhocn);
  }

  return false;
};

export const mapCustomClassesToComponent = (
  componentName: LayoutComponents,
  brandCode: NonNullable<LayoutData['brandCode']>,
  isTailored: boolean
) => {
  if (Object.hasOwn(componentBrandClassMap, brandCode) && isTailored) {
    const className =
      componentBrandClassMap[brandCode as keyof typeof componentBrandClassMap]?.[componentName];

    return cx(className);
  }

  return '';
};

export const minifyInlineStyles = (styles: string) =>
  styles
    .replace(/\/\*.*?\*\//g, '')
    .replace(/\s+/g, ' ')
    .trim();
