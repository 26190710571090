export const BUTTON_VARIANT = {
  OUTLINE: 'outline',
  SOLID: 'solid',
} as const;

/** Environment used for property configuration. */
export const CONFIG_ENV = {
  DEV: 'dev',
  PROD: 'prod',
} as const;

export const CONFIGURATION_VARIANTS = {
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E',
  INSPIRED: 'INSPIRED',
} as const;

export const HotelPage = {
  HOME: 'home',
  GALLERY: 'gallery',
  HOTELINFO: 'hotel-info',
  ROOMS: 'rooms',
  DINING: 'dining',
  LOCATION: 'hotel-location',
  MEETINGSEVENTS: 'events',
  SPA: 'spa',
  GOLF: 'golf',
  EXPLORE: 'explore',
  ACTIVITIES: 'activities',
  OFFERS: 'offers',
} as const;

export const PROPERTY_TEMPLATE = {
  INSPIRED: 'inspired',
  TAILORED: 'tailored',
} as const;

/**
 * Instructs the Configuration Service to use the default Property UI configuration.
 *
 * @see [Base Configuration](https://gitlab.svc-m.hhc.hilton.com/pac/hiw/dx-ui/-/blob/main/apps/dx-property-ui/config/base-config.ts?ref_type=heads#L7) for environment-specific definitions.
 */
export const USE_BASE_CONFIGURATION = Symbol('USE_BASE_CONFIGURATION');
