import { ENTERPRISE_THEME_REFRESH_TOGGLE_NAME } from '@dx-ui/framework-theme';

import type { TConductricsConfigurationTargets } from '../config/types';

export * from '../config/constants';

export const defaultPhoneNumbers = {
  number: '8556721138',
  friendlyNumber: '855-672-1138',
};

export const HotelImageVariantSize = {
  Md: 'md',
  Sm: 'sm',
  Xs: 'xs',
} as const;

export const HotelGolfFeature = {
  cartRental: 'cartRental',
  instruction: 'instruction',
  practiceFacilities: 'practiceFacilities',
  proShop: 'proShop',
} as const;

export const disableMetricsCookie = 'OHW_PROP_disable_metrics';

export const goUserTypes = ['TMH', 'TPH', 'FF', 'OAS', 'ODM', 'GHFT', 'GHFF'];

export const facebookAppId = '253862148111843';

type IAppConfig = {
  DEFAULT_BRAND_CODE: string;
  DEFAULT_BRAND_NAME: string;
  DISABLE_METRICS_COOKIE: string;
};

export const config: IAppConfig = {
  DEFAULT_BRAND_CODE: 'HH',
  DEFAULT_BRAND_NAME: 'Hilton',
  DISABLE_METRICS_COOKIE: 'OHW_PROP_disable_metrics',
};

export const grayBlurDataUrl =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN88h8AAs0B5QWO2loAAAAASUVORK5CYII=';

export const GLOBAL_NAMESPACES = [
  'accessibility-grid',
  'alerts',
  'common',
  'map',
  'dx-search',
  'events',
  'hotel-policies',
  'dx-utility-rail',
  'osc-alert',
  'osc-breadcrumbs',
  'osc-calendar',
  'osc-carousel',
  'osc-chip',
  'osc-date-picker',
  'osc-dialog',
  'osc-footer',
  'osc-form',
  'osc-header',
  'osc-info-popup',
  'osc-language-selector',
  'osc-link',
  'osc-location',
  'osc-login',
  'osc-pagination',
  'osc-rooms',
  'osc-shop-form',
  'osc-speedbump',
  'osc-special-rates',
  'osc-tooltip',
  'trip-advisor',
  'osc-join-form-form',
  'osc-join-form-join',
  'osc-join-form-join-button',
];

export const DayOfTheWeek = {
  MON: 'monday',
  TUE: 'tuesday',
  WED: 'wednesday',
  THR: 'thursday',
  FRI: 'friday',
  SAT: 'saturday',
  SUN: 'sunday',
} as const;

export const PROPERTY_DATA_QUERY_KEY = ['propertyData'] as const;

export const EARLY_DEPARTURE_FEE = { FIXED: 'fixed', PERCENTAGE: 'percentage' } as const;

/**
 * Return `DO_NOT_RENDER` from the component data mapper when you do not want a
 * component to render on the page.
 */
export const DO_NOT_RENDER = { DO_NOT_RENDER: true } as const;

export const CUSTOMER_SERVICE_URL = 'https://help.hilton.com/s/';

export const RESERVATION_URL = 'https://www.hilton.com/en/book/reservation/find/';

export const SEARCH_STATE_QUERY_PARAMS = {
  AVAILABLE: [
    'aaaRate',
    'aarpRate',
    'arrivalDate',
    'corporateCode',
    'departureDate',
    'flexibleDates',
    'governmentOrMilitaryRate',
    'groupCode',
    'numAdults',
    'numChildren',
    'offerId',
    'promotionCode',
    'redeemPts',
    'room1ChildAges',
    'room1NumAdults',
    'room1NumChildren',
    'room2ChildAges',
    'room2NumAdults',
    'room2NumChildren',
    'room3ChildAges',
    'room3NumAdults',
    'room3NumChildren',
    'room4ChildAges',
    'room4NumAdults',
    'room4NumChildren',
    'room5ChildAges',
    'room5NumAdults',
    'room5NumChildren',
    'room6ChildAges',
    'room6NumAdults',
    'room6NumChildren',
    'room7ChildAges',
    'room7NumAdults',
    'room7NumChildren',
    'room8ChildAges',
    'room8NumAdults',
    'room8NumChildren',
    'room9ChildAges',
    'room9NumAdults',
    'room9NumChildren',
    'seniorRate',
    'sessionToken',
    'smbRate',
    'specialRateTokens',
    'travelAgentRate',
  ],
  DATES: ['arrivalDate', 'departureDate', 'flexibleDates'],
  ROOM_AGES: [
    'room1ChildAges',
    'room2ChildAges',
    'room3ChildAges',
    'room4ChildAges',
    'room5ChildAges',
    'room6ChildAges',
    'room7ChildAges',
    'room8ChildAges',
    'room9ChildAges',
  ],
  ROOM_NUM: [
    'numAdults',
    'numChildren',
    'room1NumAdults',
    'room1NumChildren',
    'room2NumAdults',
    'room2NumChildren',
    'room3NumAdults',
    'room3NumChildren',
    'room4NumAdults',
    'room4NumChildren',
    'room5NumAdults',
    'room5NumChildren',
    'room6NumAdults',
    'room6NumChildren',
    'room7NumAdults',
    'room7NumChildren',
    'room8NumAdults',
    'room8NumChildren',
    'room9NumAdults',
    'room9NumChildren',
  ],
  SPECIAL_RATES: [
    'aaaRate',
    'aarpRate',
    'corporateCode',
    'governmentOrMilitaryRate',
    'groupCode',
    'offerId',
    'promotionCode',
    'redeemPts',
    'seniorRate',
    'smbRate',
    'specialRateTokens',
    'travelAgentRate',
  ],
};

export const CONDUCTRICS_GOALS = {
  ALL_POLICIES_CTA_ENGAGEMENT: 'g-all-policies-cta-engagement',
  AMENITIES_CTA_ENGAGEMENT: 'g-amenities-cta-engagement',
  CURRENCY_SELECTOR: 'g-currency-selector',
  EDIT_CALENDAR_DATES: 'g-edit-dates',
  EDIT_PROPERTY_ROOMS_PAGE_SEARCH_WIDGET: 'g-hsC9M5lG1Y',
  EDIT_PROPERTY_SEARCH_WIDGET: 'edit-property-search-widget',
  EDIT_SEARCH_WIDGET: 'g-8xd5mdahgj',
  ENGAGE_CHECK_RATES_CTA: 'EngageCheckRatesCTA',
  ENGAGE_PROPERTY_QUICKBOOK_CTA: 'engage-property-quickbook-cta',
  ENGAGE_VIRTUAL_TOUR_MODAL_CTA: 'g-engage-virtual-tour-in-modal-cta',
  FLEXIBLE_DATES_CTA: 'g-lNDlYVzRu9',
  GALLERY_IMAGE_VIEWS_SINGLE: 'g-gallery-image-views-single-count',
  GALLERY_IMAGE_VIEWS_TOTAL: 'g-gallery-image-views-total',
  GALLERY_VIEW_ROOMS_CTA: 'g-gallery-view-rooms-cta-click',
  GME_CTA_ENGAGEMENT: 'g-pP3RA9TJuz',
  HERO_IMAGE_CAROUSEL_ENGAGEMENT: 'g-hero-image-carousel-engagement-first',
  HERO_IMAGE_CAROUSEL_ENGAGEMENT_TOTAL: 'g-hero-image-carousel-engagement-total',
  MORE_PRICES_AVAILABLE_CTA: 'g-MorePricesAvailableCTA',
  PROPERTY_ROOMS_TAB_PAGE_LOAD: 'property-rooms-tab-page-load',
  ROOMS_AND_SUITES_ENGAGEMENT: 'g-rooms-and-suites-section-engagement',
  STAY_TOUR_VIDEO_SECONDARY_CTA: 'g-stay-tour-video-secondary-cta-click',
  SUCCESSFUL_LOGIN_FROM_PROPERTY: 'successful-login-from-property',
  TRIPADVISOR_CTA_CLICK: 'g-TripadvisorCtaClick',
  USP_CTA_ENGAGEMENT: 'g-usp-cta-engagement',
  VIDEO_CLICK_CTA: 'g-9-16-video-cta-click',
  VIEW_ALL_ROOMS_CTA: 'g-view-all-rooms-cta',
};

export const FEATURE = {
  TOGGLE: {
    OHWPR_1757_Essential_Rooms: 'OHWPR_1757_Rooms',
    CP_6321_Events_URL_Change: 'CP-6321',
    OHWPR_2641_TabbedOverview: 'OHWPR_2641_TabbedOverview',
    OHWPR_3099_PlanYourStay: 'OHWPR_3099',
    OHWPR_3015_Featured_Amenities: 'OHWPR_3015_Featured_Amenities',
    OHWPR_3402_Stay_Tour_Video: 'OHWPR_3402_Stay_Tour_Video',
    OHWPR_2671_Virtual_Tours: 'OHWPR_2671_Virtual_Tours',
    OHWPR_3978_Early_Departure_Fee: 'OHWPR_3978',
    OHWPR_4205_PIM_CMS_EMAIL: 'OHWPR_4205_PIM_CMS_EMAIL',
    [ENTERPRISE_THEME_REFRESH_TOGGLE_NAME]: ENTERPRISE_THEME_REFRESH_TOGGLE_NAME,
  },
  CONFIG: {
    OHWPR_4040_renovated_properties: 'config-renovated-properties',
  },
} as const;

/**
 * A constant array mapping Conductrics agent IDs to their corresponding Property UI configuration targets.
 *
 * **⚠️ Important:** Manually update this constant with the correct Conductrics edge worker agent ID and
 * configuration target when setting up server-side A/B/n tests.
 *
 * @example
 * export const CONDUCTRICS_CONFIGURATION_TARGETS = [
 *  {
 *     agentId: 'agent-123', // provided by Conductrics
 *     type: 'base',
 *   },
 *   {
 *     agentId: 'agent-234',
 *     type: 'brand',
 *     identifier: 'ES',
 *   }
 * ];
 */
export const CONDUCTRICS_CONFIGURATION_TARGETS = [
  {
    agentId: 'a-qzbmRkEeeF29',
    type: 'brand',
    identifier: 'PY',
  },
] as const satisfies TConductricsConfigurationTargets;

export const SEGMENTATION_COOKIE_NAME = 'TMS';
export const SEGMENTATION_COOKIE_NAME_DEV = 'TMS_DEV';
