import '../styles/global.css';
import Head from 'next/head';
import { useRouter } from 'next/router';
import type { AppProps as NextAppProps } from 'next/app';
import type { DehydratedState } from '@tanstack/react-query';
import { appWithTranslation } from '@dx-ui/framework-i18n';
import { useManualPageLogger } from '@dx-ui/framework-logger';
import { QueryProvider } from '@dx-ui/framework-react-query';
import { getQueryProviderProps } from '@dx-ui/framework-react-query-env-vars';
import { LocationProvider } from '@dx-ui/framework-location-provider';
import type { OneLinkConfig } from '@dx-ui/framework-i18n';
import type { MakeQueryClientArgs } from '@dx-ui/framework-react-query';
import { env } from '@dx-ui/framework-env';
import { ThemeStyles } from '../components/hotel-theme/hotel-theme';
import type { ThemeColors } from '../helpers/themes/customTheme';
import { MetricsFunctionalComponent as MetricsProvider } from '../helpers/metrics/withMetrics';
import type { TTrackingData } from '../helpers/metrics/metrics.types';
import { GlobalConfigProvider } from '../hooks/use-global-config';
import type { TAppConfig } from '../config/types';

const APP_NAME = 'dx-property-ui';

const queryProps = getQueryProviderProps();

const STALE_TIME_IN_MS = 432e5; // 12 hours

const reactQueryClientOverrideOptions: MakeQueryClientArgs['reactQueryClientOverrideOptions'] = {
  staleTime: STALE_TIME_IN_MS,
};

type AppProps = NextAppProps<{
  dehydratedQueryState: DehydratedState;
  globalConfig: TAppConfig['globalConfig'];
  metrics: TTrackingData;
  oneLinkConfig: OneLinkConfig;
  themeColors: ThemeColors | null;
}> & {
  isEdgeworkerPoc: boolean;
};

function App({ Component, pageProps, router }: AppProps) {
  const routerLocale = useRouter().locale || router.locale || 'en';
  useManualPageLogger(router.pathname, APP_NAME);

  return (
    <QueryProvider
      {...queryProps}
      dehydratedQueryState={pageProps.dehydratedQueryState}
      reactQueryClientOverrideOptions={reactQueryClientOverrideOptions}
      appName={APP_NAME}
      oneLinkConfig={pageProps.oneLinkConfig}
      routerLocale={routerLocale}
    >
      {env('CONDUCTRICS_URI') ? (
        <Head>
          <script src={env('CONDUCTRICS_URI')} async type="text/javascript" />
        </Head>
      ) : null}
      <GlobalConfigProvider globalConfig={pageProps.globalConfig}>
        <ThemeStyles theme={pageProps.themeColors} />
        <MetricsProvider trackingData={pageProps?.metrics?.trackingData}>
          <LocationProvider>
            <Component {...pageProps} />
          </LocationProvider>
        </MetricsProvider>
      </GlobalConfigProvider>
    </QueryProvider>
  );
}

export default appWithTranslation<AppProps>(App);
