import type * as React from 'react';
import { useEffect, useRef } from 'react';
import { defaultPageData } from './constants';
import { config } from './metrics';
import type { Metrics, TTrackingData } from './metrics.types';
import { MetricsProvider } from '../providers/metrics/metrics-provider';
import { createMetrics } from '@dx-ui/config-metrics';
import { useRouter } from 'next/router';

export type InputProps = {
  disabled?: boolean;
  trackingData?: Partial<TTrackingData>['trackingData'];
  children: React.ReactNode;
};

/**
 * TODO: Move metrics component to its own context.
 * - Research if AdobeTagManager from shared-ui is setting its own state.
 * - ExtendedAdobeTagManager may need to be set as a ref.
 *
 */

const MetricsFunctionalComponent = function ({
  children,
  disabled = false,
  trackingData = {
    pageData: defaultPageData,
    pageView: 'propertyPageView',
  },
}: InputProps) {
  const metricsAPI: Metrics = createMetrics(config).api;
  const metrics = useRef<Metrics>(metricsAPI).current;
  const hasSetMetrics = useRef(false);
  const { locale = 'en' } = useRouter();

  useEffect(() => {
    if (disabled || hasSetMetrics.current) {
      return;
    }

    metrics.setDefaultPageData([
      {
        ...defaultPageData,
        ...trackingData?.pageData,
        lang: locale,
      },
    ]);

    // This loads the Adobe TM scripts
    metrics.trackPageView(trackingData?.pageView || 'propertyPageView');

    if (trackingData?.pageData?.pageName === 'rooms') {
      metrics.trackRoomsPageView();
    }

    hasSetMetrics.current = true;
  }, [disabled, locale, metrics, trackingData?.pageData, trackingData?.pageView]);

  return <MetricsProvider metrics={metrics}>{children}</MetricsProvider>;
};

export { MetricsFunctionalComponent };
export default MetricsFunctionalComponent;
