import type { amenitiesOverviewThemeOptions } from '../../components/amenities-overview/theme';
import type { descriptionAmenitiesThemeOptions } from '../../components/description-amenities/theme';
import type { eventsInfoThemeOptions } from '../../components/events-info/theme';
import type { hotelPoliciesThemeOptions } from '../../components/property/hotel-policies/theme';
import type { halfAndHalfCarouselThemeOptions } from '../../components/half-and-half-carousel/theme';
import type { honorsThemeOptions } from '../../components/honors/theme';
import type { hotelDescriptionThemeOptions } from '../../components/hotel-description/theme';
import type { locationAndTransportationThemeOptions } from '../../components/location-and-transportation/theme';
import type { meetingsAndEventsThemeOptions } from '../../components/meetings-and-events/theme';
import type { partnershipInfoThemeOptions } from '../../components/partnership-info/theme';
import type { patchworkGridThemeOptions } from '../../components/patchwork-grids/theme';
import type { roomsOverviewThemeOptions } from '../../components/rooms-overview/theme';
import type { singleAmenityCollageThemeOptions } from '../../components/single-amenity-collage/theme';
import type { stayTourVideoThemeOptions } from '../../components/stay-tour-video/theme';
import type { stickySideBySideThemeOptions } from '../../components/sticky-side-by-side-component/theme';
import type {
  japaneseDiningThemeOptions,
  tabbedOverviewThemeOptions,
} from '../../components/tabbed-overview/theme';
import type { utilityRailThemeOptions } from '../../components/utility-rail/theme';
import type { verticalPolicyTabsThemeOptions } from '../../components/vertical-policy-tabs/theme';
import type { voucherInfoThemeOptions } from '../../components/voucher-info/theme';

import type { homePageComponents, THomePageComponents } from '../components';
import type { ValuesOf } from '../types';

type OmitNever<T> = { [K in keyof T as T[K] extends never ? never : K]: T[K] };

type OmitEmptyObjects<T> = {
  [K in keyof T as T[K] extends NO_ADDITIONAL_THEME_OPTIONS
    ? keyof T[K] extends never
      ? never
      : K
    : K]: T[K];
};

type SanitizeThemeOptions<T> = OmitEmptyObjects<OmitNever<T>>;

type HandleAdditionalThemeOptions<T> = T extends TComponentThemeOptions['additionalThemeOptions']
  ? {
      [K in keyof T]: T[K] extends readonly (infer U)[] ? U : HandleAdditionalThemeOptions<T[K]>;
    }
  : never;

type HandleAccentThemeOptions<T> = T extends TComponentThemeOptions['accent']
  ? {
      [K in keyof T]: T[K] extends true ? Required<boolean> : never;
    }
  : never;

type HandleChildThemeOptions<T> = T extends TComponentThemeOptions['childTheme']
  ? T extends true
    ? TBaseComponentTheme
    : {
        [K in keyof T]: T[K] extends TComponentThemeOptions['accent']
          ? HandleAccentThemeOptions<T[K]>
          : T[K] extends TComponentThemeOptions['additionalThemeOptions']
          ? HandleAdditionalThemeOptions<T[K]>
          : never;
      } & TBaseComponentTheme
  : never;

type ComponentThemeConfiguration<T extends TComponentThemeOptions> = {
  accent: HandleAccentThemeOptions<T['accent']>;
  additionalThemeOptions: HandleAdditionalThemeOptions<T['additionalThemeOptions']>;
  childTheme: HandleChildThemeOptions<T['childTheme']>;
};

/**
 *  Use this type when the component does not support any additional theme options.
 */
type NO_ADDITIONAL_THEME_OPTIONS = Record<never, never>;

type ComponentThemeMap = {
  [homePageComponents.AMENITIES_OVERVIEW]: SanitizeThemeOptions<
    ComponentThemeConfiguration<typeof amenitiesOverviewThemeOptions>
  >;
  [homePageComponents.CELEBRITY_QUOTE]: NO_ADDITIONAL_THEME_OPTIONS;
  [homePageComponents.DESCRIPTION_AMENITIES]: SanitizeThemeOptions<
    ComponentThemeConfiguration<typeof descriptionAmenitiesThemeOptions>
  >;
  [homePageComponents.EVENTS_INFO]: SanitizeThemeOptions<
    ComponentThemeConfiguration<typeof eventsInfoThemeOptions>
  >;
  [homePageComponents.FULL_WIDTH_DYNAMIC_HERO]: NO_ADDITIONAL_THEME_OPTIONS;
  [homePageComponents.FULL_WIDTH_IMAGE_CAROUSEL]: NO_ADDITIONAL_THEME_OPTIONS;
  [homePageComponents.FULL_WIDTH_VIDEO]: NO_ADDITIONAL_THEME_OPTIONS;
  [homePageComponents.HALFANDHALF_CAROUSEL]: SanitizeThemeOptions<
    ComponentThemeConfiguration<typeof halfAndHalfCarouselThemeOptions>
  >;
  [homePageComponents.HONORS]: SanitizeThemeOptions<
    ComponentThemeConfiguration<typeof honorsThemeOptions>
  >;
  [homePageComponents.HOTEL_DESCRIPTION]: SanitizeThemeOptions<
    ComponentThemeConfiguration<typeof hotelDescriptionThemeOptions>
  >;
  [homePageComponents.HOTEL_POLICIES]: SanitizeThemeOptions<
    ComponentThemeConfiguration<typeof hotelPoliciesThemeOptions>
  >;
  [homePageComponents.INSPIRED_HERO]: NO_ADDITIONAL_THEME_OPTIONS;
  [homePageComponents.JAPANESE_DINING]: SanitizeThemeOptions<
    ComponentThemeConfiguration<typeof japaneseDiningThemeOptions>
  >;
  [homePageComponents.LOCATION_AND_TRANSPORTATION]: SanitizeThemeOptions<
    ComponentThemeConfiguration<typeof locationAndTransportationThemeOptions>
  >;
  [homePageComponents.MEETINGS_AND_EVENTS]: SanitizeThemeOptions<
    ComponentThemeConfiguration<typeof meetingsAndEventsThemeOptions>
  >;
  [homePageComponents.PARTNERSHIP_INFO]: SanitizeThemeOptions<
    ComponentThemeConfiguration<typeof partnershipInfoThemeOptions>
  >;
  [homePageComponents.PATCHWORK_GRIDS]: SanitizeThemeOptions<
    ComponentThemeConfiguration<typeof patchworkGridThemeOptions>
  >;
  [homePageComponents.PROPERTY_ALERTS]: NO_ADDITIONAL_THEME_OPTIONS;
  [homePageComponents.ROOMS_OVERVIEW]: SanitizeThemeOptions<
    ComponentThemeConfiguration<typeof roomsOverviewThemeOptions>
  >;
  [homePageComponents.SINGLE_AMENITY_COLLAGE]: SanitizeThemeOptions<
    ComponentThemeConfiguration<typeof singleAmenityCollageThemeOptions>
  >;
  [homePageComponents.STAY_TOUR_VIDEO]: SanitizeThemeOptions<
    ComponentThemeConfiguration<typeof stayTourVideoThemeOptions>
  >;
  [homePageComponents.STICKY_SIDE_BY_SIDE]: SanitizeThemeOptions<
    ComponentThemeConfiguration<typeof stickySideBySideThemeOptions>
  >;
  [homePageComponents.TABBED_OVERVIEW]: SanitizeThemeOptions<
    ComponentThemeConfiguration<typeof tabbedOverviewThemeOptions>
  >;
  [homePageComponents.UTILITY_RAIL]: SanitizeThemeOptions<
    ComponentThemeConfiguration<typeof utilityRailThemeOptions>
  >;
  [homePageComponents.VERTICAL_POLICY_TABS]: SanitizeThemeOptions<
    ComponentThemeConfiguration<typeof verticalPolicyTabsThemeOptions>
  >;
  [homePageComponents.VOUCHER_INFO]: SanitizeThemeOptions<
    ComponentThemeConfiguration<typeof voucherInfoThemeOptions>
  >;
};

export type TComponentThemeOptions = {
  accent?: {
    border?: true;
    heading?: true;
    hover?: true;
    icon?: true;
  };
  /**
   * An object containing any component specific theme options.
   *
   * Use an Array to define multiple options, for instance if you want to define
   * font sizes, you'll create the following additionalThemeOptions object:
   * ```
   * additionalThemeOptions: {
   *    fontSize: ['xs', 'sm', 'md', 'lg', 'xl'],
   * };
   * ```
   * Which will correlate to the following in the configuration:
   * ```
   * theme: {
   *  ...
   *  additionalThemeOptions: {
   *    fontSize: 'xs' | 'sm' | 'md' | 'lg' | 'xl',
   *  },
   * };
   * ```
   */
  additionalThemeOptions?: Record<string, unknown>;
  childTheme?: Omit<TComponentThemeOptions, 'childTheme'> | true;
};

export type TBaseComponentTheme = {
  /** The base theme is applied through the theme provider. */
  base: ValuesOf<typeof availableThemes>;
};

export type TComponentTheme<T extends THomePageComponents> = TBaseComponentTheme &
  ComponentThemeMap[T];

export type TWithCustomComponentTheme<
  ComponentProps,
  ComponentName extends THomePageComponents
> = ComponentProps & {
  /**
   * Theme definitions can be found in the component's corresponding `theme.ts` file.
   *
   * Component themes are configured in this application's `/config` folder.
   * @see `/config/base-config.ts`
   */
  theme: TComponentTheme<ComponentName>;
};

export const availableThemes = {
  inherit: '',
  primary: 'theme-primary',
  secondary: 'theme-secondary',
  tertiary: 'theme-tertiary',
} as const;
