import Head from 'next/head';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import { meetsContrastGuidelines, parseToRgb, rgbToColorString } from 'polished';
import { availableThemes } from '../../config/themes/componentThemes';
import { useGlobalConfig } from '../../hooks/use-global-config';
import { usePropertyData } from '../../hooks/use-property-data';
import { className, minifyInlineStyles } from '../../helpers/themes/customTheme';

import type { ThemeColors } from '../../helpers/themes/customTheme';

type ThemeVariant = keyof Omit<ThemeColors, `${string}Contrast`>;

const brandsSupportingLogoColorVariants = new Set(['gu']);

const toRgb = (hex: string) => {
  const { red: r, green: g, blue: b } = parseToRgb(hex.trim());

  return `${r} ${g} ${b}`;
};

const adjustHeaderLogoColor = (variant: ThemeVariant, brandCode: string) => {
  if (brandsSupportingLogoColorVariants.has(brandCode)) {
    return applyWhiteVariant(variant)
      ? '--header-logo-filter: contrast(2) grayscale(1) invert(1);'
      : '--header-logo-filter: contrast(2) grayscale(1);';
  }
};

const applyWhiteVariant = (variant: ThemeVariant) => {
  if (isBrowser) {
    const [r, g, b] = getComputedStyle(document.body)
      ?.getPropertyValue(`--theme-colors-${variant}-contrast`)
      .split(' ');
    const colorString =
      r && g && b
        ? rgbToColorString({ red: Number(r), green: Number(g), blue: Number(b) })
        : '#000000';

    const { AA: applyWhiteVariant } = meetsContrastGuidelines(colorString, '#000000');

    return applyWhiteVariant;
  }

  return false;
};

const generateBaseTokens = (brandCode: string, isTailored: boolean, theme: ThemeColors | null) => {
  if (theme && isTailored) {
    return `
      --theme-colors-primary: ${toRgb(theme.primary)};
      --theme-colors-primary-contrast: ${toRgb(theme.primaryContrast)};
      --theme-colors-secondary: ${toRgb(theme.secondary)};
      --theme-colors-secondary-contrast: ${toRgb(theme.secondaryContrast)};
      --theme-colors-tertiary: ${toRgb(theme.tertiary)};
      --theme-colors-tertiary-contrast: ${toRgb(theme.tertiaryContrast)};
    `;
  }

  const accentColors = `
     /* accent colors */
    --theme-colors-accent: var(--color-${brandCode}-accent, var(--color-global-accent));
    --theme-colors-border-accent: var(--color-${brandCode}-border-accent);
    --theme-colors-heading-accent: var(--color-${brandCode}-heading-accent);
    --theme-colors-icon-accent: var(--color-${brandCode}-icon-accent);
  `;

  if (isTailored) {
    return `
    --theme-colors-primary: var(--color-${brandCode}-primary, var(--color-global-primary));
    --theme-colors-primary-contrast: var(--color-${brandCode}-primary-contrast, var(--color-global-primary-contrast));
    --theme-colors-secondary: var(--color-${brandCode}-secondary, var(--color-global-secondary));
    --theme-colors-secondary-contrast: var(--color-${brandCode}-secondary-contrast, var(--color-global-secondary-contrast));
    --theme-colors-tertiary: var(--color-${brandCode}-tertiary, var(--color-global-tertiary));
    --theme-colors-tertiary-contrast: var(--color-${brandCode}-tertiary-contrast, var(--color-global-tertiary-contrast));

    ${accentColors}
  `;
  }

  return `
    --theme-colors-primary: var(--color-global-primary);
    --theme-colors-primary-contrast: var(--color-global-primary-contrast);
    --theme-colors-secondary: var(--color-global-secondary);
    --theme-colors-secondary-contrast: var(--color-global-secondary-contrast);
    --theme-colors-tertiary: var(--color-${brandCode}-accent, var(--color-global-tertiary));
    --theme-colors-tertiary-contrast: var(--color-global-tertiary-contrast);

    ${accentColors}
  `;
};

const buttonTokens = (variant: ThemeVariant, isPropertyLevelTheming: boolean) =>
  isPropertyLevelTheming
    ? `
  --btn-bg-color-solid: var(--theme-colors-${variant}-contrast);
  --btn-bg-hover-color-solid: var(--theme-colors-${variant});
  --btn-text-color-solid: var(--theme-colors-${variant});
  --btn-text-hover-color-solid: var(--theme-colors-${variant}-contrast);
  --btn-border-color-solid: var(--theme-colors-${variant}-contrast);
  --btn-border-hover-color-solid: var(--theme-colors-${variant}-contrast);
  --btn-bg-color-outline: var(--theme-colors-${variant});
  --btn-bg-hover-color-outline: var(--theme-colors-${variant}-contrast);
  --btn-text-color-outline: var(--theme-colors-${variant}-contrast);
  --btn-text-hover-color-outline: var(--theme-colors-${variant});
  --btn-border-color-outline: var(--theme-colors-${variant}-contrast);
  --btn-border-hover-color-outline: var(--theme-colors-${variant}-contrast);

  --btn-bg-hover-color-dark-solid: var(--theme-colors-${variant});
  --btn-border-hover-color-dark-solid: var(--theme-colors-${variant}-contrast);
  --btn-text-hover-color-dark-solid: var(--theme-colors-${variant}-contrast);

  ${
    variant === 'tertiary'
      ? `
    --shop-form-btn-text-color: var(--theme-colors-${variant}-contrast);
    --shop-form-btn-text-hover-color: var(--theme-colors-${variant});
    --shop-form-btn-bg-color: var(--theme-colors-${variant});
    --shop-form-btn-bg-hover-color: var(--theme-colors-${variant}-contrast);
    --shop-form-btn-border-color: var(--theme-colors-${variant}-contrast);
    --shop-form-btn-border-hover-color: var(--theme-colors-${variant}-contrast);
    --shop-form-btn-submit-text-color: var(--theme-colors-${variant});
    --shop-form-btn-submit-text-hover-color: var(--theme-colors-${variant}-contrast);
    --shop-form-btn-submit-bg-color: var(--theme-colors-${variant}-contrast);
    --shop-form-btn-submit-bg-hover-color: var(--theme-colors-${variant});
    --shop-form-btn-submit-border-color: var(--theme-colors-${variant}-contrast);
    --shop-form-btn-submit-border-hover-color: var(--theme-colors-${variant}-contrast);
  `
      : ''
  }`
    : '';

const commonThemeVariables = (
  brandCode: string,
  isPropertyLevelTheming: boolean,
  variant: ThemeVariant
) => `
  --color-background: var(--theme-colors-${variant});
  --color-text: var(--theme-colors-${variant}-contrast);
  --color-accent: var(--theme-colors-accent);
  --color-text-heading: var(--theme-colors-${variant}-contrast);
  --color-border: var(--theme-colors-${variant}-contrast);
  
  color: rgb(var(--theme-colors-${variant}-contrast));
  background-color: rgb(var(--theme-colors-${variant}));
  
  ${buttonTokens(variant, isPropertyLevelTheming)}
  ${adjustHeaderLogoColor(variant, brandCode)};
`;

function customThemeVariables(variant: ThemeVariant) {
  return `
    color: rgb(var(--theme-colors-${variant}-contrast));
    background-color: rgb(var(--theme-colors-${variant}));

    --color-bg: var(--theme-colors-${variant});
    --color-text: var(--theme-colors-${variant}-contrast);
    --color-text-heading: var(--theme-colors-${variant}-contrast);
    --color-bg-alt: var(--theme-colors-${variant}-contrast);
    --color-brand-alt: var(--theme-colors-${variant}-contrast);
    --color-border: var(--theme-colors-${variant}-contrast);
    --btn-bg-color-solid: var(--theme-colors-${variant}-contrast);
    --btn-bg-hover-color-solid: var(--theme-colors-${variant});
    --btn-text-color-solid: var(--theme-colors-${variant});
    --btn-text-hover-color-solid: var(--theme-colors-${variant}-contrast);
    --btn-border-color-solid: var(--theme-colors-${variant}-contrast);
    --btn-border-hover-color-solid: var(--theme-colors-${variant}-contrast);
    --btn-bg-color-outline: var(--theme-colors-${variant});
    --btn-bg-hover-color-outline: var(--theme-colors-${variant}-contrast);
    --btn-text-color-outline: var(--theme-colors-${variant}-contrast);
    --btn-text-hover-color-outline: var(--theme-colors-${variant});
    --btn-border-color-outline: var(--theme-colors-${variant}-contrast);
    --btn-border-hover-color-outline: var(--theme-colors-${variant}-contrast);

    ${
      variant === 'tertiary'
        ? `
            --btn-bg-color-solid: var(--theme-colors-${variant}-contrast);
            --btn-bg-hover-color-solid: var(--theme-colors-${variant});
            --btn-text-color-solid: var(--theme-colors-${variant});
            --btn-text-hover-color-solid: var(--theme-colors-${variant}-contrast);
            --shop-form-btn-text-color: var(--theme-colors-${variant}-contrast);
            --shop-form-btn-text-hover-color: var(--theme-colors-${variant});
            --shop-form-btn-bg-color: var(--theme-colors-${variant});
            --shop-form-btn-bg-hover-color: var(--theme-colors-${variant}-contrast);
            --shop-form-btn-border-color: var(--theme-colors-${variant}-contrast);
            --shop-form-btn-border-hover-color: var(--theme-colors-${variant}-contrast);
            --shop-form-btn-submit-text-color: var(--theme-colors-${variant});
            --shop-form-btn-submit-text-hover-color: var(--theme-colors-${variant}-contrast);
            --shop-form-btn-submit-bg-color: var(--theme-colors-${variant}-contrast);
            --shop-form-btn-submit-bg-hover-color: var(--theme-colors-${variant});
            --shop-form-btn-submit-border-color: var(--theme-colors-${variant}-contrast);
            --shop-form-btn-submit-border-hover-color: var(--theme-colors-${variant}-contrast);
          `
        : ''
    }
  `;
}

export function ThemeStyles(props: { theme: ThemeColors | null }) {
  const { isTailored } = useGlobalConfig();
  const brandCode = usePropertyData()?.brandCode.toLowerCase() ?? '';
  const isPropertyLevelTheming = Boolean(props.theme);

  return (
    <Head>
      <style>
        {minifyInlineStyles(`
          .theme-${brandCode} {
            ${generateBaseTokens(brandCode, isTailored, props.theme)}
          }
          .theme-${brandCode} .${availableThemes.primary} {
            ${commonThemeVariables(brandCode, isPropertyLevelTheming, 'primary')};
          }
          .theme-${brandCode} .${availableThemes.secondary} {
            ${commonThemeVariables(brandCode, isPropertyLevelTheming, 'secondary')};
          }
          .theme-${brandCode} .${availableThemes.tertiary} {
            ${commonThemeVariables(brandCode, isPropertyLevelTheming, 'tertiary')};
          }
          .theme-${brandCode} .${className.primary} {
            ${customThemeVariables('primary')}
          }
          .theme-${brandCode} .${className.secondary} {
            ${customThemeVariables('secondary')}
          }
          .theme-${brandCode} .${className.tertiary} {
            ${customThemeVariables('tertiary')}
          }
          .theme-${brandCode} .${className.colorPrimaryOverride} {
            --color-primary: var(--color-text);
            --color-primary-alt: var(--color-text);
          }
          .theme-${brandCode} .${className.navItemHoverPrimary} .nav-list-item-active,
          .theme-${brandCode} .${className.navItemHoverPrimary} .nav-list-item:focus,
          .theme-${brandCode} .${className.navItemHoverPrimary} .nav-list-item:focus-within,
          .theme-${brandCode} .${className.navItemHoverPrimary} .nav-list-item:hover {
            --color-text: var(--theme-colors-primary-contrast);
            --header-item-hover-color: var(--theme-colors-primary);
            --header-item-hover-border-color: var(--theme-colors-primary-contrast);
            --nav-main-link-text-color: var(--theme-colors-primary-contrast);
            color: rgb(var(--theme-colors-primary-contrast));
          }
          .theme-${brandCode} .${className.navItemHoverSecondary} .nav-list-item-active,
          .theme-${brandCode} .${className.navItemHoverSecondary} .nav-list-item:focus,
          .theme-${brandCode} .${className.navItemHoverSecondary} .nav-list-item:focus-within,
          .theme-${brandCode} .${className.navItemHoverSecondary} .nav-list-item:hover {
            --color-text: var(--theme-colors-secondary-contrast);
            --header-item-hover-color: var(--theme-colors-secondary);
            --header-item-hover-border-color: var(--theme-colors-secondary-contrast);
            --nav-main-link-text-color: var(--theme-colors-secondary-contrast);
            color: rgb(var(--theme-colors-secondary-contrast));
          }
          .theme-${brandCode} .${className.navItemHoverTertiary} .nav-list-item-active,
          .theme-${brandCode} .${className.navItemHoverTertiary} .nav-list-item:focus,
          .theme-${brandCode} .${className.navItemHoverTertiary} .nav-list-item:focus-within,
          .theme-${brandCode} .${className.navItemHoverTertiary} .nav-list-item:hover {
            --color-text: var(--theme-colors-tertiary-contrast);
            --header-item-hover-color: var(--theme-colors-tertiary);
            --header-item-hover-border-color: var(--theme-colors-tertiary-contrast);
            --nav-main-link-text-color: var(--theme-colors-tertiary-contrast);
            color: rgb(var(--theme-colors-tertiary-contrast));
          }
          .theme-${brandCode} .${className.navItemHoverBorder} {
            --nav-item-border-width: 8px;
          }
          .theme-${brandCode} .${className.navLowerPrimary} .nav-bottom-link-area {
            --header-base-color: var(--theme-colors-primary);
            --nav-bottom-area-base-color: var(--theme-colors-primary);
            --nav-main-link-text-color: var(--theme-colors-primary-contrast);
          }
          .theme-${brandCode} .${className.navLowerSecondary} .nav-bottom-link-area {
            --header-base-color: var(--theme-colors-secondary);
            --nav-bottom-area-base-color: var(--theme-colors-secondary);
            --nav-main-link-text-color: var(--theme-colors-secondary-contrast);
          }
          .theme-${brandCode} .${className.navLowerTertiary} .nav-bottom-link-area {
            --header-base-color: var(--theme-colors-tertiary);
            --nav-bottom-area-base-color: var(--theme-colors-tertiary);
            --nav-main-link-text-color: var(--theme-colors-tertiary-contrast);
          }
      `)}
      </style>
    </Head>
  );
}
